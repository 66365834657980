@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src: local('Poppins Regular'), local('Poppins Regular 2'), url('Poppins/woff/Poppins-Regular.woff') format('woff'),
  url('Poppins/woff2/Poppins-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Monospace';
  font-weight: 400;
  font-style: normal;
  src: local('SpaceMono-Regular'), local('Prompt Regular 2'), url('SpaceMono/SpaceMono-Regular.ttf') format('ttf'),
  url('Prompt/woff2/Prompt-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  font-weight: 400;
  font-style: normal;
  src: local('Prompt Regular'), local('Prompt Regular 2'), url('Prompt/woff/Prompt-Regular.woff') format('woff'),
  url('Prompt/woff2/Prompt-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  font-weight: 500;
  font-style: normal;
  src: local('Prompt SemiBold'), local('Prompt SemiBold 2'), url('Prompt/woff/Prompt-SemiBold.woff') format('woff'),
  url('Prompt/woff2/Prompt-SemiBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Pulse';
  font-weight: 300;
  font-style: normal;
  src: local('Pulse Light'), local('Pulse Light 2'), url('Pulse/woff/Pulse-light.woff') format('woff'),
  url('Pulse/woff2/Pulse-light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Pulse';
  font-weight: 400;
  font-style: normal;
  src: local('Pulse Regular'), local('Pulse Regular 2'), url('Pulse/woff/Pulse-regular.woff') format('woff'),
  url('Pulse/woff2/Pulse-regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Pulse';
  font-weight: 600;
  font-style: normal;
  src: local('Pulse Bold'), local('Pulse Bold 2'), url('Pulse/woff/Pulse-bold.woff') format('woff'),
  url('Pulse/woff2/Pulse-bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Fono';
  src: local('Aeonik Fono Black'), local('Aeonik Fono Black 2'), url('AeonikFono/woff/AeonikFono-Black.woff') format('woff'),
  url('AeonikFono/woff2/AeonikFono-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Fono';
  src: url('AeonikFono/woff/AeonikFono-Black.woff') format('woff'),
  url('AeonikFono/woff2/AeonikFono-Black.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Fono';
  src: url('AeonikFono/woff/AeonikFono-Bold.woff') format('woff'),
  url('AeonikFono/woff2/AeonikFono-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Aeonik Fono';
  src: url('AeonikFono/woff/AeonikFono-Medium.woff') format('woff'),
  url('AeonikFono/woff2/AeonikFono-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
